import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Main from '../views/Main.vue'
import Main1 from '../views/Main1.vue'

const routes = [
  {
    path: '/',
    name: 'Main1',
    component: Main1,
    redirect: '/main',
    children: [
      {
        path: '/main',
        component: Main,
        name: 'main',
        redirect: '/main/my',
        children: [{
          path: 'coupon',
          component: () => import('@/views/coupon/index'),
          name: 'coupon',
        },
        {
          path: 'gift',
          component: () => import('@/views/gift/index'),
          name: 'gift',
        },
        {
          path: 'shop',
          component: () => import('@/views/shop/index'),
          name: 'shop',
        },
        {
          path: 'vip',
          component: () => import('@/views/vip/index'),
          name: 'vip',
        },
        {
          path: 'vip/signin',
          component: () => import('@/views/vip/page/signIn'),
          name: 'signin',
        },
        {
          path: 'vip/meet',
          component: () => import('@/views/vip/page/meet'),
          name: 'meet',
        },
        {
          path: 'vip/nap',
          component: () => import('@/views/vip/page/nap'),
          name: 'nap',
        },
        {
          path: 'vip/recovery',
          component: () => import('@/views/vip/page/recovery'),
          name: 'recovery',
        },
        {
          path: 'vip/retrieve',
          component: () => import('@/views/vip/page/retrieve'),
          name: 'retrieve',
        },
        {
          path: 'vip/vipcoupon',
          component: () => import('@/views/vip/page/vipCoupon'),
          name: 'vipcoupon',
        },
        {
          path: 'vip/vipgift',
          component: () => import('@/views/vip/page/vipGift'),
          name: 'vipgift',
        },
        {
          path: 'my',
          component: () => import('@/views/my/index'),
          name: 'my',
        },
        {
          path: 'my/userInfo',
          component: () => import('@/views/my/page/userInfo'),
          name: 'userInfo',
        },
        {
          path: 'my/napOrder',
          component: () => import('@/views/my/page/napOrder'),
          name: 'napOrder',
        },
        {
          path: 'my/setPassword',
          component: () => import('@/views/my/page/setPassword'),
          name: 'setPassword',
        },
        {
          path: 'my/account',
          component: () => import('@/views/my/page/account'),
          name: 'account',
        },
        {
          path: 'my/gate',
          component: () => import('@/views/my/page/gate'),
          name: 'gate',
        },
        {
          path: 'my/install',
          component: () => import('@/views/my/page/install'),
          name: 'install',
        },
        {
          path: 'my/userAgreement',
          component: () => import('@/views/my/page/userAgreement'),
          name: 'userAgreement',
        },]
      },
      {
        path: 'login',
        component: () => import('@/views/login/index'),
        name: 'login',
      },
      {
        path: 'login/setPassword',
        component: () => import('@/views/login/page/setPassword'),
        name: 'loginSetPassword',
      },
      {
        path: 'login/register',
        component: () => import('@/views/login/page/register'),
        name: 'register',
      },
      {
        path: 'announcement',
        component: () => import('@/views/login/page/announcement'),
        name: 'announcement',
      },
      {
        path: 'coupon',
        component: () => import('@/views/login/page/coupon'),
        name: 'loginCoupon',
      },
    ]
  },
  {
    path: "/h5Result",
    name: "h5Result",
    component: () => import("../views/h5Result"),
  },
  {
    path: "/payCode",
    name: "payCode",
    component: () => import("../views/payCode"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: '/main',
    name: '404',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
